@import "node_modules/bootstrap-sass/assets/stylesheets/bootstrap";

$openSans: "Open Sans", sans-serif;
$white: #fff;
$black: #000;
$red: #ee2c30;

/*----------*/

.no-padding{
	padding: 0;
}

.no-margin{
	margin:0;
}

/*-----Header-----*/
header{
	position: absolute;
	top:0;
	left: 0;
	right: 0;

	@media only screen and (max-width: 767px){
		position: relative;
		text-align: center;
	}

	img.logo{
		max-width: 160px;
		margin: 30px 66px;
	}
}


/*-----Banner-----*/
.banner{
	background-image: url(https://wallpapersite.com/images/wallpapers/cute-kittens-2560x1440-adorable-hd-5655.jpg);
	background-size: cover;
	-webkit-clip-path: polygon(0 24%, 100% 4%, 100% 100%, 0% 100%);
	clip-path: polygon(0 24%, 100% 4%, 100% 100%, 0% 100%);
	height: 770px;
	position: relative;

	@media only screen and (max-width: 767px){
		-webkit-clip-path: inherit;
		clip-path:inherit;
		height: 400px;
	}

	.banner_content{
		position: absolute;
		top: 50%;
		left: 0;
		right: 0;
		transform: translateY(-50%);

		h2, p{
			color: $white;
			font-family: $openSans;
			font-weight: 600;
			font-size: 24px;
			line-height: 33px;

			@media only screen and (max-width: 767px){
				font-size: 20px;
				line-height: 29px;
			}

			span{
				text-transform: uppercase;
				font-size: 64px;
				line-height: 75px;
				letter-spacing: -1px;
				font-weight: 800;
				text-shadow: 0 6px 5px rgba(0,0,0,0.38);
				display: block;

				@media only screen and (max-width: 767px){
					font-size: 40px;
					line-height: 50px;
				}
			}
		}
	}

	#arrowAnim{
		position: absolute;
		z-index: 10;
		bottom: 65px;
		left: 0;
		right: 0;
		text-align: center;

		a{
			display: inline-block;
		}

		.arrowSliding {
			position: relative;
			text-align: center;
			-webkit-animation: opacity 0.9s infinite; 
			animation: opacity 0.9s infinite;
			animation-duration: 3s;

	        &.delay1 {
				-webkit-animation-delay: 0.3s; 
			    animation-delay: 0.3s;
			}
			&.delay2 {
				-webkit-animation-delay: 0.6s; 
				animation-delay: 0.6s;
			}
			&.delay3 {
				-webkit-animation-delay: 0.9s; 
				animation-delay: 0.9s;
			}
		}

		.arrow{

			img{
				width: 30px;
			}
		}

		@media only screen and (max-width: 767px){
			display: none;
		}
	}
}

@-webkit-keyframes opacity{
	0% { opacity:0.3; }  
   20% { opacity:1; }   
   80% { opacity:1; }  
  100% { opacity:0.3; }
}

@keyframes opacity{
	0% { opacity:0.3; }  
   20% { opacity:1; }   
   80% { opacity:1; }  
  100% { opacity:0.3; }
}


/*-----Content-----*/
section.content{
	background: #f3f0f0;
	padding: 64px 0;

	@media only screen and (max-width: 1999px){
		padding: 40px 0;
	}

	h3{
		font-family: $openSans;
		font-size: 24px;
		line-height: 33px;
		font-weight: bold;
		color: $red;
		margin-top: 0;
		margin-bottom: 30px;
	}

	.card-network{
		img{
			width: 100%;
		}

		@media only screen and (max-width: 991px){
			margin-bottom: 32px;
		}

		.card-network_content{
			position: relative;

			.card-network_content-logo{
				position: absolute;
				bottom: 0;
				left: 0;
				display: block;
				width: 100px;
				height: 0;
				border-style: solid;
				border-width: 0 290px 160px 0;
				border-color: transparent transparent $black transparent;

				img{
					position: absolute;
				    bottom: -135px;
				    left: 20px;
				    width: 140px;
				    height: auto;
				}
			}	
		}
	}

	.content_text{
		padding: 80px 0;

		@media only screen and (max-width: 1999px){
			padding: 50px 0;
		}

		@media only screen and (max-width: 767px){
			padding: 30px 0;
		}

		p{
			font-family: $openSans;
			font-size: 24px;
			line-height: 33px;
			color: $black;
			font-weight: 600;
		}
	}

	.content-map{
		background: $white;

		img{
			width: 100%;
		}

		.content-map_right{

			.filters{
				height: 188px;
				padding: 16px;
				border-bottom: 3px solid rgba(0,0,0,0.2);

				.filter-category, .filter-city{
					span{
						font-family: $openSans;
						font-weight: 800;
						text-transform: uppercase;
						display: block;
						margin-bottom: 10px;
					}

					select, input{
						appearance: none;
						border: 2px solid $black;
						height: 42px;
						width: 100%;
						border-radius: 16px;
						background: $white;
						padding-left: 20px;
						padding-right: 60px;
						font-family: $openSans;
						font-size: 14px;
						line-height: 19px;
						color: $black;
						font-weight: 800;
						text-transform: uppercase;

						&:focus{
							outline: none;
						}
					}

					input::-webkit-input-placeholder { /* Chrome/Opera/Safari */
						color: $black;
					}
					input::-moz-placeholder { /* Firefox 19+ */
						color: $black;
					}
					input:-ms-input-placeholder { /* IE 10+ */
						color: $black;
					}
					input:-moz-placeholder { /* Firefox 18- */
						color: $black;
					}
				}

				.filter-category{
					span{
						font-size: 16px;
						line-height: 22px;
						color: $red;
					}

					select{
						margin-bottom: 10px;
						background: $white url(../assets/images/arrow-dropdown.svg) no-repeat 97%;
						cursor: pointer;
					}
				}

				.filter-city{
					span{
						font-size: 14px;
						line-height: 19px;
						opacity: 0.2;
						color: $black;
					}

					.inputCity{
						position: relative;

						button{
							appearance: none;
							border: none;
							background: none;
							position: absolute;
							right: 5px;
							top: 50%;
							transform: translateY(-50%);
						}
					}
				}
			}

			.list-stores{
				max-height: 522px;
				overflow-y: scroll;
				overflow-x: hidden;

				&>.row{
					padding: 16px;
					border-bottom: 2px solid #f3f3f3;

					img{
						max-width: 80%;
					}

					h4{
						text-transform: uppercase;
						color: $black;
						font-family: $openSans;
						font-size: 14px;
						line-height: 19px;
						font-weight: 800;
						margin-bottom: 5px;
					}

					p{
						font-size: 12px;
						line-height: 15px;
						font-family: $openSans;
						margin-bottom: 5px;
					}

					a{
						font-family: $openSans;
						color: $red;	
						font-weight: 600;
						font-size: 14px;
					}
				}	
			}
		}
	}
}


/*-----Footer-----*/
footer{
	background: $black;
	padding: 33px 0;

	ul{
		list-style-type: none;
		margin: 0;
		padding: 0;

		li{
			display: inline-block;
			padding: 0 40px;

			@media only screen and (max-width: 767px){
				display: block;
				padding: 10px 0;
			}

			a{
				font-family: $openSans;
				font-size: 14px;
				line-height: 19px;
				color: $white;
				font-weight: 300;
				opacity: 1;
				-webkit-transition: opacity 0.5s ease-in-out;
			    -moz-transition: opacity 0.5s ease-in-out;
			    transition: opacity 0.5s ease-in-out;

				&:hover{
					text-decoration: none;
					opacity: 0.5;
					color: $white;
				}
			}

			&:first-of-type{
				a{
					font-weight: bold;
				}
			}
		}
	}
}